import React, { useEffect, useState } from 'react'
import BodyDefaultItem from './BodyDefaultItem'
import BodyDefaultFixItem from './BodySpotFixItem'
import PaymentItem from '../PaymentItem'

function BodyDefault(props:PropsValue) {

  const [list, setList] = useState(null)
  const [list_regular, setRegularList] = useState(null)
  const [list_spot, setSpotList] = useState(null)
  const [list_add, setAdd] = useState(null)
  const [repot_list , setReportList] = useState([]) //paymentItem
  const [repo_list_updated_at , setRepoListUpdatedAt] = useState(new Date())

  

  useEffect(() => {
 
    setReportList(props.repot_list)
    setRepoListUpdatedAt(new Date())
 
  },[ props.repot_list, props.repo_list_updated_at])


  
  useEffect(() => { 

    if(repot_list &&( props.partner || props.driver) && props.params && props.monthly_payment){

      //var total_target = 0  
      var date = null
    
      //comitionable
      const _list_r = []
      const _list_s = []
      const _list_a = []
      const _list = []
      const _un_comittion_list = []

      repot_list.map((item, index) => {

        const _index_a = index
        if(item.date && date === null){
          date = item.date 
        }
        
        if(item.is_non_commition){
          _un_comittion_list.push(item) 
        }else{
          //total_target +=  item.getTotal()
          const _index_b = _index_a - _un_comittion_list.length
          const content = <BodyDefaultItem index={_index_b} partner={props.partner} editable={props.editable} item={item} updateRowHadler={(_item) => updateRowHadler(_item, _index_a)} deleteItem={() => deleteItem(_index_a)}/>
          if(item.is_self){
            _list_s.push(content)
          }else if(item.is_add){
            _list_a.push(content)
          }else{
            _list_r.push(content)
          }
          //_list.push() 
        } 
        
        return null
      })
     
      setRegularList(_list_r)
      setSpotList(_list_s)
      setAdd(_list_a)

      const counter = _list_r.length  + _list_s.length + _list_a.length
      if(props.editable){
        _list.push( <tr className="new_row"><td></td><td></td><td onClick={() => props.addItem(new PaymentItem({is_edit_all:true, is_add:true}))}>新しい行を追加　　</td><td ></td><td></td><td></td><td></td><td></td><td></td></tr>)
      }

      const total_target = props.monthly_payment ? props.monthly_payment.getTotalWorlingPrice(repot_list, props.partner) : 0
      const roy = props.monthly_payment ? props.monthly_payment.getCommitionPrice(total_target, props.driver, props.partner) : 0
  
      _list.push(<BodyDefaultFixItem index={counter + 1} item={props.fixed_list[0]}  date={null} editable={props.editable} label="（業務手数料対象額　小計）" total={total_target} updateRowHadler={(_item) => props.changeFixedHandler(_item, 0)}/>)
      _list.push(<BodyDefaultFixItem index={counter + 2} item={props.fixed_list[1]}  date={null} editable={props.editable} label={"業務手数料 (" +  props.monthly_payment.getCommition(props.driver, props.partner) + "%)"} total={-1 * roy} updateRowHadler={(_item) => props.changeFixedHandler(_item, 1)}/>)
      _list.push(<BodyDefaultFixItem index={counter + 3} item={props.fixed_list[3]}  date={null} editable={props.editable} label="高速代" total={props.params.highway_cost} total_value_param_name ="highway_cost" changeParamHandler={props.changeParamHandler} updateRowHadler={(_item) => props.changeFixedHandler(_item, 3)}/>)
      _list.push(<BodyDefaultFixItem index={counter + 4} item={props.fixed_list[4]}  date={null} editable={props.editable} label="駐車場代" total={props.params.parking_cost} total_value_param_name ="parking_cost" changeParamHandler={props.changeParamHandler} updateRowHadler={(_item) => props.changeFixedHandler(_item, 4)}/>)
      _list.push(<BodyDefaultFixItem index={counter + 5} item={props.fixed_list[5]}  date={null} editable={props.editable} label="その他" total={props.params.other_cost} total_value_param_name ="other_cost" changeParamHandler={props.changeParamHandler} updateRowHadler={(_item) => props.changeFixedHandler(_item, 5)}/>)

      const counter_u = counter + 5
      
      _un_comittion_list.map((item, index) => {
        const _index = counter_u + index
        const _index_for_item = _index - 6
     
        _list.push(
          <BodyDefaultItem index={_index} editable={props.editable} item={item} updateRowHadler={(_item) => updateRowHadler(_item, _index_for_item)} deleteItem={() => deleteItem(_index_for_item)}/>
        )
        return null
      })
     
      //const target_price = getTotalTargetPrice(total_target, roy, uncomittion_total_target)
      //const target_price = props.monthly_payment.getTotalTaxTargetPrice(total_target, roy, uncomittion_total_target)
            //const total_price = getTotalPrice(target_price)

      const target_price = props.monthly_payment.getTotalTaxTargetPrice(total_target, roy)
      const tax = props.monthly_payment.getTaxByTotalPrice(target_price, props.partner)
      const non_tax_total = props.monthly_payment.getNonTaxByTotalPrice(props.params)
      const non_tax_total_tax = props.monthly_payment.getTaxInNonTaxByTotalPrice(props.params)
      const total = props.monthly_payment.getTotalWithTax(target_price, props.partner, props.params)
     

      _list.push(<tr><td  className="lefttop w80" colSpan={7} rowSpan={4}>(備考) 10%対象:{total.toLocaleString()}円 (内消費税額 {(tax + non_tax_total_tax).toLocaleString()}円)</td><td  >消費税10%対象</td><td className="right w80">{target_price.toLocaleString()}</td> </tr>)
      _list.push(<tr><td className="w80" >{props.partner && Number(props.partner.tax_include) === 2 ? "消費税額" : "内消費税額"}</td><td className="right w80">({tax.toLocaleString()})</td> </tr>)
      _list.push(<tr><td className="w80" >内税対象小計</td><td className="right w80">{non_tax_total}</td> </tr>)
      _list.push(<tr><td className="w80">支払金額合計</td><td className="right w80">{total.toLocaleString()}</td> </tr>)
    
      setList(_list)
      props.updateTotal(total)

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[ repo_list_updated_at , props.partner,props.driver, props.params, props.monthly_payment])

  const updateRowHadler = ( item, index) => {
    props.changeHandler(item, index)
  }

  const deleteItem = (index) => {
    props.deleteItem(index, false)
  }

  /** 
  const getTotalTargetPrice = (total_target, roy, uncomittion_total_target) => {
   return  (total_target - roy 
      + uncomittion_total_target
      + Number(props.params.energy_money ? props.params.energy_money : 0)
      + Number(props.params.highway_cost) 
      + Number(props.params.parking_cost)
      + Number(props.params.other_cost))

}**/
/**
  const getTotalPrice = (_t) => {
  
      if(props.partner && Number(props.partner.tax_include) === 1){
        return _t
      }else if(props.partner && Number(props.partner.tax_include) === 2){
        //return Math.round(_t - (_t / 110) * 10 ) 
        return  _t
      }else {
        return _t
      }
  }
   */
  







  return(
    <table className="monthly-payment">
    
      <tbody>
      <tr>
        <th>No</th>
        <th>月日</th>
        <th className="w100">車番</th>
        <th>作業明細</th>
        <th className="w60">{props.editable ? <input type="text" value={props.params.title_1 ? props.params.title_1 : ""} name="title_1" onChange={(e) => props.changeParamHandler(e, true)}/> : props.params.title_1 ? props.params.title_1 : ""}</th>
        <th className="w60">立替金</th>
        <th className='w60'>数量</th>
        <th className="w80">単価 {props.partner && Number(props.partner.tax_include) === 2 ? "(税別)" : "(内税)"}</th>
        <th className="w80">金額{props.partner && Number(props.partner.tax_include) === 2 ? "(税別)" : "(内税)"}</th>
      </tr>
      {list_regular}
      {list_regular && list_regular.length > 0 ? <tr><td colSpan={9}>　</td></tr> : null}
      {list_spot}
      {list_add}
      {list}
      
      </tbody>
    </table>
    
  )
}

export default BodyDefault
